import axios, { resHandle } from "@/plugins/axios";

/**
 * @description 获取舌图图片列表
 * @param vCom Vue组件
 */
const GetTonguePicLists = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 删除舌图
 * @param vCom Vue组件
 */
const DeleteTongue = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/tongue/args", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 舌图智能比对报告预览
 * @param vCom Vue组件
 */
const BiduiPreview = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/duibi/report2", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 报告预览
 * @param vCom Vue组件
 */
const GetTonguePreview = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/tongue/report", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description  标签列表
 */
const getBiaoqianList = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/zwb/user/tags").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 标签 修改
 */
const saveBiaoqianList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zwb/user/tags", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 该患者的全部舌图
 * @param vCom Vue组件
 */
const GetPatientAllTongue = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/tongue/patient/tongues", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 望舌体、望舌苔、分组 获取详情
 * @param vCom Vue组件
 */
const GetTongueDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/tongue/args", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 望舌体、望舌苔、分组 标注
 * @param vCom Vue组件
 */
const SaveTongueDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/args", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 舌图手动分割
 * @param vCom Vue组件
 */
const Fenge = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/cut", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 目图手动分割
 * @param vCom Vue组件
 */
const FengeEye = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/cut", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 手掌手动分割
 * @param vCom Vue组件
 */
const FengePalm = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/cut", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 局部舌色计算
 * @param vCom Vue组件
 */
const Jubushese = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/part/shese", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 局部苔色计算
 * @param vCom Vue组件
 */
const Jubutaise = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/part/taise", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 舌上红点计算
 * @param vCom Vue组件
 */
const Hongdian = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/redspot", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 计算分割后舌图的像素面积
 * @param vCom Vue组件
 */
const CalCuttedTongueArea = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/tongue/mianji", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取舌图标注页面的来源
 * @param vCom Vue组件
 */
const PostSources = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/center/sources", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取舌图标注页面的创建人
 * @param vCom Vue组件
 */
const PostCreators = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/center/search", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取目图列表
 * @param vCom Vue组件
 */
const GetEyeList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取脉图列表
 * @param vCom Vue组件
 */
const GetPulseList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/pulse/list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取手图列表
 * @param vCom Vue组件
 */
const GetHandList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传目图可选专病库
 * @param vCom Vue组件
 */
const GetEyePro = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/eye/select/source_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传手掌可选专病库
 * @param vCom Vue组件
 */
const GetPalmPro = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/palm/select/source_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传目图可选患者
 * @param vCom Vue组件
 */
const GetEyePatient = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/research/patient_name", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传手掌可选患者
 * @param vCom Vue组件
 */
const GetPalmPatient = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/research/patient_name", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传目图可选诊次 
 * @param vCom Vue组件
 */
const GetEyeZhenci = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/select/zhenci", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传手掌可选诊次 
 * @param vCom Vue组件
 */
const GetPalmZhenci = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/select/zhenci", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传目图可选创建人
 * @param vCom Vue组件
 */
const GetEyeCreator = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/research/creator", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取上传手掌可选创建人
 * @param vCom Vue组件
 */
const GetPalmCreator = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/research/creator", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取目图可选标签
 * @param vCom Vue组件
 */
const GetEyeTags = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/eye/tag", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取脉图可选标签
 * @param vCom Vue组件
 */
const GetPulseTags = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/pulse/tag", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取手掌可选标签
 * @param vCom Vue组件
 */
const GetPalmTags = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/palm/tag", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑目图标签
 * @param vCom Vue组件
 */
const EditEyeTags = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/tag", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑脉图标签
 * @param vCom Vue组件
 */
const EditPulseTags = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/pulse/tag", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑手掌标签
 * @param vCom Vue组件
 */
const EditPalmTags = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/tag", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 上传目图
 * @param vCom Vue组件
 */
const UploadEye = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/create/eye_diagnosis", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 上传手掌
 * @param vCom Vue组件
 */
const UploadPalm = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/create/palm", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取目图详情
 * @param vCom Vue组件
 */
const GetEyeDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/eye/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取手掌详情
 * @param vCom Vue组件
 */
const GetPalmDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/palm/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取脉图详情
 * @param vCom Vue组件
 */
const GetMaiDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/zwb/patient/type/details", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取脉图详情2
 * @param vCom Vue组件
 */
const GetMaiDetail2 = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/pulse/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑目图详情
 * @param vCom Vue组件
 */
const EditEyeDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/update/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑脉诊详情
 * @param vCom Vue组件
 */
const EditPulseDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/pulse/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 编辑手掌详情
 * @param vCom Vue组件
 */
const EditPalmDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/update/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取目图详情页面诊次
 * @param vCom Vue组件
 */
const GetEyeDetailZhenci = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/eye/patient/zhenci", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取脉诊详情页面诊次
 * @param vCom Vue组件
 */
const GetMaiDetailZhenci = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/pulse/patient/zhenci", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 获取手掌详情页面诊次
 * @param vCom Vue组件
 */
const GetPalmDetailZhenci = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/palm/patient/zhenci", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 专病库通过诊次和项目拿取目图数据
 * @param vCom Vue组件
 */
const GetZhenciEyeData = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/eye/template/eye", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 专病库通过诊次和项目拿取手掌数据
 * @param vCom Vue组件
 */
const GetZhenciPalmData = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/palm/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 专病库通过诊次和项目修改目图图片
 * @param vCom Vue组件
 */
const EditEyeImg = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/eye/template/eye", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 专病库通过诊次和项目修改手掌图片
 * @param vCom Vue组件
 */
const EditPalmImg = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/palm/dimension", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
/**
 * @description 根据脉名判断紧张度和流利度
 * @param vCom Vue组件
 */
const GetLiulidu = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/pulse/judge/param", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
export {
    GetTonguePicLists, DeleteTongue, BiduiPreview, GetTonguePreview,
    getBiaoqianList, saveBiaoqianList, GetPatientAllTongue, GetTongueDetail,
    SaveTongueDetail, Fenge, FengeEye, FengePalm, Jubushese, Jubutaise, Hongdian,
    CalCuttedTongueArea, PostSources, PostCreators, GetEyeList, GetHandList,
    GetPulseList, GetEyePro,
    GetPalmPro,
    GetEyePatient, GetPalmPatient, GetEyeZhenci, GetPalmZhenci,
    UploadEye, UploadPalm, GetEyeCreator, GetPalmCreator,
    GetEyeTags, GetPulseTags, GetPalmTags, EditEyeTags, EditPulseTags, EditPalmTags,
    GetEyeDetail, GetPalmDetail,
    GetEyeDetailZhenci, GetMaiDetailZhenci, GetPalmDetailZhenci,
    EditEyeDetail, EditPulseDetail, EditPalmDetail, GetZhenciEyeData, GetZhenciPalmData, EditEyeImg, EditPalmImg,
    GetMaiDetail, GetMaiDetail2, GetLiulidu
}