





















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { clearFilter } from "@/utils/base";
import { getNavLeftText } from "@/utils/base";
import { GetCurrentUserData } from "@/request/account";
@Component({})
export default class YhqxNavLeft extends Vue {
  @Prop()
  private cParent: any;
  private user: any = "";
  private get center() {
    return this.$store.state.center;
  }
  private get href() {
    return window.location.href;
  }
  private get configuration() {
    console.log(1233);
    console.log(this.$store.state.configuration[this.cParent].child);
    return this.$store.state.configuration[this.cParent].child;
  }
  private getNavLeftText(name: any) {
    return getNavLeftText(name);
  }
  private goRoute(item: any) {
    clearFilter();
    this.$router.push(item.path);
  }
  /**
   * @description 判断当前用户在当前机构下能否显示某个功能
   */
  private canThisRoleUse(tool: any) {
    const ifShowShenhe = this.$store.state.center.auditing_show;
    let canUse = false;
    try {
      switch (tool) {
        case "数据采集":
          if (!this.user || !this.user["role"] || !this.user["role"]["名称"]) {
            return canUse;
          }
          if (
            this.$store.state.roleControl["数据录入"].indexOf(
              this.user["role"]["名称"]
            ) !== -1
          ) {
            canUse = true;
          } else {
            canUse = false;
          }
          break;
        case "质量控制":
          if (!this.user || !this.user["role"] || !this.user["role"]["名称"]) {
            return canUse;
          }
          if (
            this.$store.state.roleControl["监查痕迹"].indexOf(
              this.user["role"]["名称"]
            ) !== -1 &&
            ifShowShenhe
          ) {
            canUse = true;
          } else {
            canUse = false;
          }
          break;
        case "监查痕迹":
          if (!this.user || !this.user["role"] || !this.user["role"]["名称"]) {
            return canUse;
          }
          if (
            this.$store.state.roleControl["监查痕迹"].indexOf(
              this.user["role"]["名称"]
            ) !== -1 &&
            ifShowShenhe
          ) {
            canUse = true;
          } else {
            canUse = false;
          }
          break;
        case "角色管理":
          if (
            this.user &&
            this.user["role"] &&
            this.user["role"]["名称"] &&
            this.user["role"]["名称"] == "项目负责人"
          ) {
            canUse = true;
          } else {
            canUse = false;
          }
          break;
        case "资料管理":
          if (this.user && this.user["用户名"] == "admin") {
            canUse = true;
          } else {
            canUse = false;
          }
          break;
        default:
          canUse = true;
          break;
      }
    } catch (e) {
      console.log(e);
    }
    return canUse;
  }
  private getUserInfo() {
    if (!this.center || !this.center.project_id) {
      this.user = this.$store.state.user;
      return;
    }
    const params = {
      params: {
        project_id: this.center.project_id,
      },
    };
    GetCurrentUserData(this, params).then((res) => {
      this.user = res;
      this.$store.commit("updateUser", res);
    });
  }
  private mounted() {
    this.getUserInfo();
  }
}
